<template>
  <div class="order-list">
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh; position: absolute; left: 50%"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div class="parent_block">
      <b-row class="block1" style="height: calc(100vh - 92px)">
        <b-col cols="12">
          <b-table
            striped
            small
            id="myTable"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="orders"
            :fields="field"
            head-row-variant="secondary"
            :tbody-tr-class="rowClass"
            @row-dblclicked="selectOrder"
            @row-clicked="clicked"
            v-hotkey="{
              F2: openHandle,
              'alt + F5': copyOrder,
              F11: openHistoryModal,
            }"
            @row-contextmenu="handleContextMenuEvent"
            @row-hovered="hovering"
            @row-unhovered="unhovering"
          >
            <template #cell(checkbox)="row">
              <input type="checkbox" :value="row.item.id" v-model="checked" />
            </template>
            <template #cell(create_user)="data">
              {{ data.item.create_user ? data.item.create_user.login : "" }}
            </template>
            <template #cell(performer_name)="data">
              {{
                data.item.performer == null
                  ? ""
                  : data.item.performer.last_name +
                    " " +
                    data.item.performer.first_name
              }}
            </template>
            <template #cell(performer_phone)="data">
              {{ data.item.performer ? data.item.performer.phone : "" }}
            </template>
            <template #cell(performer_login)="data">
              <span
                @dblclick="getLogin(data)"
                v-if="data.item.performer"
                class="reference-field"
              >
                {{ data.item.id ? data.item.performer.login : "" }}
              </span>
            </template>
            <template #cell(performer_transport)="data">
              {{
                data.item.performer == null ||
                data.item.performer.transport == null
                  ? ""
                  : data.item.performer.transport.model +
                    ` ${data.item.performer.transport.color}` +
                    ` ${data.item.performer.transport.car_number}`
              }}
            </template>
            <template #cell(distance)="data">
              {{
                data.item.distance != null
                  ? data.item.distance.toFixed(1) + "км"
                  : ""
              }}
            </template>
            <template #cell(allowances)="data">
              <span
                v-for="(allowance, index) in data.item.allowances"
                :key="allowance.id"
              >
                {{
                  data.item.allowances.length - 1 === index
                    ? allowance.name
                    : allowance.name + "; "
                }}
              </span>
            </template>
            <template #cell(client_status)="data">
              {{ data.item.client_status == 0 ? "Не оповещён" : "Оповещён" }}
            </template>
            <template #cell(report)="data">
              <span @dblclick="getAssigment(data)" class="reference-field">
                (***)
              </span>
            </template>

            <template #cell(history)="data">
              <span
                @dblclick="getPathData(data)"
                class="reference-field"
                ref="textDiv"
              >
                (***)
              </span>
            </template>

            <template #cell(history_call)="data">
              <span @dblclick="getHistoryCall(data)" class="reference-field">
                (***)
              </span>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                @click="getOrderByIds()"
                v-b-modal.handle_orders
              >
                <feather-icon icon="EditIcon" size="16" />
                <span class="ml-75">Обработать</span>
              </b-link>

              <b-link
                class="d-flex align-items-center"
                @click="showOnTheMap()"
                v-hotkey="{ 'ctrl+m': showOnTheMap }"
              >
                <feather-icon icon="NavigationIcon" size="16" />
                <span class="ml-75">Открыть на карте</span>
              </b-link>

              <b-link
                class="d-flex align-items-center"
                @click="getOrderById()"
                v-b-modal.edit_order
              >
                <feather-icon icon="Edit2Icon" size="16" />
                <span class="ml-75">Изменить</span>
              </b-link>
              <b-link
                class="d-flex align-items-center"
                @click="getOrderById()"
                v-b-modal.show_order
              >
                <feather-icon icon="EyeIcon" size="16" />
                <span class="ml-75">Просмотр</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="copyOrder()">
                <feather-icon icon="CopyIcon" size="16" />
                <span class="ml-75">Копировать заказ</span>
              </b-link>
              <b-link
                class="d-flex align-items-center"
                v-b-modal.messageCreateModal
              >
                <feather-icon icon="MailIcon" />
                <span class="ml-75">Отправить СМС</span>
              </b-link>
              <b-link
                class="d-flex align-items-center"
                v-b-modal.messageCreateModalDriver
              >
                <feather-icon icon="MailIcon" />
                <span class="ml-75">Отправить СМС водителю </span>
              </b-link>
              <b-link
                v-if="positionId == 1"
                class="d-flex align-items-center"
                v-b-modal.order_history
              >
                <feather-icon icon="BookOpenIcon" size="16" />
                <span class="ml-75">История изменения</span>
              </b-link>

              <div
                v-else
                style="padding: 0.65rem 1.28rem; cursor: pointer"
                class="d-flex align-items-center"
                @click="getPath()"
              >
                <feather-icon icon="BookOpenIcon" size="16" />
                <span class="ml-75">История изменения</span>
              </div>
            </li>
          </vue-context>
        </b-col>
      </b-row>
    </div>
    <modal-order-create
      @sendNumber="sendNumber"
      :orderData="orderData"
      @refresh="refresh"
      v-hotkey="{ insert: showCreateOrder }"
      @GetOrder="getOrder"
    />
    <modal-order-handle
      :orderData="orderData"
      @refresh="refresh"
      :editOrder="editOrder"
      :orderId="orderId"
    />
    <modal-order-edit
      :orderData="orderData"
      @refresh="refresh"
      :editOrder="editOrder"
    />
    <!-- <modal-order-filter @filteredOrders="filteredOrders" v-hotkey="{'f': showFilterModal}"></modal-order-filter> -->
    <mail-modal></mail-modal>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
    <modal-order-complaint
      :userPhone="userPhone"
      @refresh="refresh"
      :editOrder="editOrder"
    ></modal-order-complaint>
    <modal-order-history :orderId="orderId"></modal-order-history>
    <modal-order-copy
      :editOrder="editOrder"
      @refresh="refresh"
      :orderData="orderData"
    ></modal-order-copy>
    <modal-send-message :clientPhone="clientPhone"></modal-send-message>
    <modal-for-driver-message
      :perfomerPhone="perfomerPhone"
    ></modal-for-driver-message>
    <modal-order-show
      :orderData="orderData"
      @refresh="refresh"
      :editOrder="editOrder"
    ></modal-order-show>
  </div>
</template>

<script>
import ModalSendMessage from "@/views/component/Modal/ModalMessage/ModalSendMessage.vue";
import ModalOrderShow from "@/views/component/Modal/ModalOrder/ModalOrderShow.vue";
import modalForDriverMessage from "@/views/component/Modal/ModalForDriverMessage/MessageForDriver.vue";
import ModalOrderCreate from "@/views/component/Modal/ModalOrder/ModalOrderCreate.vue";
import ModalOrderCopy from "@/views/component/Modal/ModalOrder/ModalOrderCopy.vue";
import ModalOrderHistory from "@/views/component/Modal/ModalOrder/ModalOrderHistory.vue";
import ModalOrderHandle from "@/views/component/Modal/ModalOrder/ModalOrderHandle.vue";
import ModalOrderEdit from "@/views/component/Modal/ModalOrder/ModalOrderEdit.vue";
import ModalOrderComplaint from "@/views/component/Modal/ModalOrder/ModalOrderСomplaint.vue";
// import ModalOrderFilter from "@/views/component/Modal/ModalOrder/ModalOrderFilter.vue"
import MailModal from "@/views/component/Modal/ModalOrder/MailModal.vue";
import VueContext from "vue-context";
import { mask } from "vue-the-mask";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import sendMassegeModal from "@/views/component/Modal/ModalOrder/sendMassegeModal.vue";
import Vue from "vue";

export default {
  directives: { mask },
  components: {
    modalForDriverMessage,
    ModalOrderShow,
    ModalSendMessage,
    ModalOrderCreate,
    ModalOrderHandle,
    ModalOrderComplaint,
    ModalOrderEdit,
    //    ModalOrderFilter,
    VueContext,
    MailModal,
    sendMassegeModal,
    ModalOrderHistory,
    ModalOrderCopy,
    Vue,
  },

  data() {
    return {
      data: [],
      checked: [],
      positionId: JSON.parse(localStorage.getItem("userData")).role_id,
      orderClone: {
        division_id: "1",
        phone: "",
        dop_phone: undefined,
        auto_assignment: 1,
        not_issued: 0,
        for_time: 0,
        date_time: undefined,
        search_address: undefined,
        to_addresses: [{}],
        number_of_passengers: undefined,
        meeting_info: undefined,
        info_for_operator: "",
        info_for_drivers: "",
        order_type_id: 1,
        tariff_id: 1,
        price: 0,
        distance: 0.0,
        allowances: [],
      },
      userPhone: undefined,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id" },
        { key: "division", label: "Подразделение" },
        { key: "last_name", label: "Водитель" },
        { key: "performer_login", label: "Логин" },
        { key: "phone", label: "Номер телефона" },
        { key: "dop_phone", label: "Доп. номер" },
        { key: "from_address.name", label: "Откуда" },
        { key: "to_addresses", label: "Куда" },
        { key: "price", label: "Цена" },
        { key: "payment_type", label: "Тип оплаты" },
        { key: "car_info", label: "Автомобиль" },
        { key: "status", label: "Статус" },
        { key: "type", label: "Тип" },
        { key: "tariff", label: "Тариф" },
        { key: "created_by", label: "Создал" },
        { key: "info_for_operator", label: "Доп. инфо для оператора" },
        { key: "info_for_driver", label: "Доп. и. в." },
        { key: "client_status", label: "Сатус клиента" },
        { key: "perfomer_phone", label: "Номер водителя" },
        { key: "assignment_by", label: "Назначил" },
        { key: "date_time", label: "Время поступления" },
        { key: "commission", label: "Коммиссия" },
      ],
      field: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "from_address.title",
          label: "Откуда",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "to_addresses[0].title",
          label: "Куда",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "price",
          label: "Цена",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "payment_type",
          label: "Тип оплаты",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "bonus_price",
          label: "Безнал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "phone",
          label: "Номер телефона",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dop_phone",
          label: "Доп. номер",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "report",
          label: "Запросы",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "past_minute",
          label: "ВП",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "tariff",
          label: "Тариф",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Время поступления",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_time",
          label: "На время",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "assignmentBy",
          label: "Назначил",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "stat_for_drivers",
          label: "Доп. инфо для водителя",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "info_for_operator",
          label: "Доп. инфо для оператора",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "create_user",
          label: "Создал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_phone",
          label: "Номер водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "client_status",
          label: "Статус клиента",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "type",
          label: "Тип",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "allowances",
          label: "Надбавки",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "distance",
          label: "Расстояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history",
          label: "История изменения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history_call",
          label: "История звонков",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "commission_price",
          label: "Коммиссия",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      items: [],
      orders: [],
      showPreloader: false,
      search_Phone: "",
      latest_order: [],
      all_search_orders: [],
      editOrder: {
        division_id: 1,
        phone: undefined,
        dop_phone: undefined,
        auto_assignment: 1,
        from_address: {
          title: null,
        },
        not_issued: 0,
        for_time: 0,
        date_time: undefined,
        search_address_id: undefined,
        to_addresses: [
          {
            search_address_id: undefined,
          },
        ],
        meeting_info: undefined,
        info_for_operator: "",
        info_for_drivers: "",
        order_type_id: 1,
        tariff_id: 1,
        price: 0,
        distance: 0.0,
        number_of_passengers: undefined,
        allowances: [],
        bonus: 0,
      },
      orderId: "",
      clientPhone: "",
      driverPhone: "",
      memos: [],
      refreshTimer: null,
      orderData: "",
      perfomerPhone: null,
      geo_location: null,
    };
  },
  methods: {
    copyOrder() {
      this.$http.get(`orders/${this.orderId}/edit`).then((res) => {
        this.editOrder = res.data;
        this.$bvModal.show("copy_order");
      });
    },
    sendNumber(number) {
      this.userPhone = number;
    },
    getOrderData() {
      this.$http.get("orders/data").then((res) => {
        this.orderData = res.data;
      });
    },
    getPath() {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + this.orderId,
        `/order/order-history`,
        this.orderId,
      ]);
      this.$router.push({
        name: "orderHistory",
        query: { filter: this.orderId },
      });
    },
    getHistoryCall(data) {
      this.$router.push({
        name: "orderHistoryCall",
        query: { filter: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Звонки " + data.item.id,
        `/order/history-call`,
        data.item.id,
      ]);
    },
    getPathData(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.id,
        `/order/order-history`,
        data.item.id,
      ]);
      this.$router.push({
        name: "orderHistory",
        query: { filter: data.item.id },
      });
    },
    getAssigment(data) {
      this.$router.push({
        name: "report/assigment",
        query: { filter: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Запросы " + data.item.id,
        `/order/assigment`,
        data.item.id,
      ]);
    },
    getLogin(data) {
      this.$router.push({
        name: "infoLogin",
        query: { filter: data.item.performer.login },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer.login,
        `/infoLogin`,
        data.item.performer.login,
      ]);
    },
    getOrderByPhone() {
      this.$http
        .get(`orders/order-by-phone?phone=${this.search_Phone}`)
        .then((res) => {
          this.latest_order = res.data.latest_order;
          this.all_search_orders = res.data.orders;
          this.memos = res.data.memos;
        });
    },
    openHistoryModal() {
      if (this.positionId == 1) {
        this.$bvModal.show("order_history");
      } else {
        this.getPath();
      }
    },
    city(e, city) {
      var button = document.getElementsByClassName("but"),
        tabContent = document.getElementsByClassName("tab-content");
      var i;
      for (i = 0; i < button.length; i++) {
        tabContent[i].style.display = "none";
        button[i].classList.remove("btn-primary");
      }
      let cityBlock = document.getElementById(city);
      cityBlock.style.display = "block";
      e.currentTarget.classList.add("btn-primary");
    },
    sendToParent(tableData) {
      this.orders = tableData;
      this.$store.commit("pageData/setdataCount", this.orders.length);
      this.$store.commit("excel/SET_ORDERS", this.orders);
    },
    filteredOrders(orders) {
      this.orders = orders;
      this.$store.commit("pageData/setdataCount", this.orders.length);
    },
    showCreateOrder() {
      this.$bvModal.show(this.$route.name + "CreateModal");
    },
    handleContextMenuEvent(item, index, event) {
      this.data = item;
      this.geo_location = item.geo_json_array;
      this.clientPhone = item.phone;
      if (item.performer) {
        this.perfomerPhone = item.performer.phone;
      }
      event.preventDefault();
      this.$refs.menu.open(event);
      this.orderId = item.id;
    },
    selectOrder(item, index, event) {
      this.$http.get(`orders/${item.id}/edit`).then((res) => {
        this.editOrder = res.data;
        this.$bvModal.show("edit_order");
      });
    },
    hovering(item, index, event) {
      event.target.style.backgroundColor = "#B8D2FF";
    },
    unhovering(item, index, event) {
      event.target.style.backgroundColor = "";
    },
    showOnTheMap() {
      let data = this.data;
      this.$router.push({ name: "showOnThe-map", query: { map: data.id } });
      this.$store.commit("draggableTab/ADD_TAB", [
        data.id,
        `/showOnThe-map`,
        data.id,
      ]);
    },
    getOrderByIds() {
      this.$http.get(`orders/${this.orderId}/edit`).then((res) => {
        this.editOrder = res.data;
        setTimeout(this.handleOrder, 0);
      });
    },
    getOrderById() {
      this.$http.get(`orders/${this.orderId}/edit`).then((res) => {
        this.editOrder = res.data;
      });
    },
    handleOrder() {
      this.$bvModal.show("handle_order");
    },
    openHandle() {
      this.$http.get(`orders/${this.orderId}/edit`).then((res) => {
        this.editOrder = res.data;
        setTimeout(this.handleOrder, 0);
      });
    },
    getOrder(orders) {
      this.orders = orders;
      this.$store.commit("pageData/setdataCount", this.orders.length);
    },
    clicked(item, index, event) {
      this.data = item;
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.field.length) {
        return;
      }

      const clickedField = this.field[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.textContent.trim();
      this.orderId = item.id;
      cellSelect();
    },
    refresh() {
      if (document.visibilityState !== "visible") return;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let access = JSON.parse(localStorage.getItem("userData"));
      let query = {
        division_id: access.division_id,
      };
      if (access.role_id == 2) {
        this.$http.get("orders", { params: param }).then((res) => {
          this.showPreloader = true;
          setTimeout(() => {
            this.orders = [];
            this.showPreloader = false;
            this.orders = res.data;
            this.$store.commit("pageData/setdataCount", this.orders.length);
          }, "200");
          this.$store.commit("excel/SET_ORDERS", this.orders);
          this.$store.commit("REFRESH_DATA", false);
        });
      } else {
        this.$http.get("orders", { params: param }).then((res) => {
          this.orders = [];
          this.$store.commit("excel/SET_ORDERS", this.orders);
          this.showPreloader = true;
          setTimeout(() => {
            this.orders = res.data;
            this.showPreloader = false;
            this.$store.commit("pageData/setdataCount", this.orders.length);
          }, "200");
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (param) {
        param.filter_phone = this.perfomerPhone;
      }
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.orders = [];
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.orders = res.data;
          this.$store.commit("pageData/setdataCount", this.orders.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
      this.$store.state.filterPlus.filterValue = null;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status_id === 10) return "table-danger";
      else if (item.status_id === 11) return "table-pink";
      else if (item.status_id === 1) return "table-success";
      else if (item.status_id === 3) return "table-secondary";
      else if (item.status_id === 2) return "";
    },
  },

  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.getOrderData();
    this.openFilter();
    this.refreshTimer = setInterval(this.refresh, 30000);
    resizeable();
  },
  destroy() {
    clearInterval(this.refreshTimer);
    this.$store.commit("excel/SET_ORDERS", []);
  },
  computed: {
    sessionState() {
      return this.$store.state.caller.sessionState;
    },
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    "$store.state.filterPlus.filterValue": {
      immediate: true,
      handler(newFilterValue) {
        if (newFilterValue) {
          this.perfomerPhone = "992" + newFilterValue;
          this.openFilter();
        }
      },
    },
    sessionState(newVal, old) {
      if (newVal) {
        this.$http
          .post("active-customer-order", {
            phone: "992" + this.$store.state.caller.phoneNumber,
          })
          .then((res) => {
            if (res.data.id && !this.$store.state.caller.isInitiator) {
              this.editOrder = res.data;
              this.$bvModal.show("handle_order");
            } else if (
              !this.$store.state.caller.isInitiator &&
              this.positionId == 1
            ) {
              this.$bvModal.show(this.$route.name + "CreateModal");
            }
          });
      }
    },
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.orders = [];
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.orders = res.data;
          this.$store.commit("pageData/setdataCount", this.orders.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
  handleCopy() {
    const textDiv = this.$refs.textDiv;
    if (textDiv) {
      const text = textDiv.innerText;
      const tempInput = document.createElement("input");
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/table.scss";
.order-card .card-body {
  padding: 0;
  margin: 0;
}
.order-card {
  width: fit-content;
}
.parent_block {
  overflow: hidden;
}
.parent_block {
  flex-direction: column;
}
.block1,
.block2 {
  overflow: scroll;
}
.order-list {
  @font-face {
    font-family: "Verdana";
    src: url("../../../assets/fonts/Verdana.ttf");
  }
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}
</style>
